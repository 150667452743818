<app-timeout></app-timeout>
<div class="container-agenda  d-flex justify-content-center">
    <div class="row  container" id="ficha">
        <div class="col-12">
            <img src="./assets/images/logo_correo.png" class="img-tama">
        </div>

        <div class="datos-paciente">
            <div class="col-12 estimado">
                <span>Estimado {{cliente.nombre}}</span>
                <p>Su cita ha sido programada.</p>
            </div>
            <div class=" form-text col-12">
                <label>
                    CLIENTE
                </label>
                <span>
                    {{Cita.Nombremasc}}
                </span>
            </div>
            <div class=" form-text col-12">
                <label>
                    Doctor
                </label>
                <span>
                    {{Cita.Doctor}}
                </span>
            </div>
            <div class=" form-text col-12">
                <label>
                    SEDE
                </label>
                <span>
                    {{Cita.Sucursal}}
                </span>
            </div>
            <div class=" form-text col-12">
                <label>
                    Ubicada en
                </label>
                <span>
                    {{Cita.Direccion}}
                </span>
            </div>
            <div class=" form-text col-12">
                <label>
                    Área de
                </label>
                <span>
                    {{Cita.Especialidad}}
                </span>
            </div>
            <div class="col-12 my-3">
                <span>
                    Por favor presentarse 10 min antes de la hora agendada el día {{Cita.fecha}} a las {{Cita.hora}}h
                </span>
            </div>
            <div class="col-12 px-4">
                <span>
                    Cod Cancelacion: {{Cita.codigoCita}}
                </span>
                <!-- <label>
                    En caso de no poder presentarse y necesite la cancelacion de su cita, presione <a href=""> Aqui </a>  he ingrese el codigo
                </label> -->
            </div>
            <div class="col-12 px-4">
                <span>
                    Nota al médico
                </span>
                <label>
                    {{Cita.descripcion}}
                </label>
            </div>

        </div>

        <div class="col-12 datos-contacto">
            <div class="row">

                <span class="col-12">
                    Teléfono : +56(2) 2789 2830 
                </span>
                <span class="col-12">
                    Whatsapp: +56 9 9188 7304
                </span>
                <span class="col-12">
                    Dirección : Av. Aeropuerto #7500, Cerrillos
                </span>
            </div>
        </div>
    </div>
    <button class="btn-siguiente ml-auto" routerLink="">
        <i class="pi pi-plus"></i>
    </button>
</div>