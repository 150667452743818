<app-modal  *ngIf="CargaCompleta"></app-modal>
<div class="container mt-3">
    <div class="row">
        <p-toast position="top-right" key="tc"></p-toast>
        <div class="form-group col-12 col-md-6">
            <label for="Nombre">Nombre Sucursal</label>
            <input maxlength="20" type="text" class="form-control" [(ngModel)]="Nombre" placeholder="Ingrese nombre de Sucursal">
        </div>

        <div class="form-group col-12 col-md-6 row mx-0">
            <label for="Direccion" class="col-12 px-0">Dirección</label>
            <div class="col-sm-10 px-0 ">
                <input  maxlength="50" type="text" class="form-control " [(ngModel)]="Direccion"
                    placeholder="Ingrese dreccion de sucursal" (blur)=" obtenerlatlon(gmap.getMap())">
            </div>
            <div class="col-sm-2 text-right px-0">
                <button class="btn btn-medisalud-primary"  (click)=" obtenerlatlon(gmap.getMap())">buscar</button>
            </div>

        </div>

        <div class="form-group col-12 col-md-6">
            <label for="Horini">Hora de Inicio de servicio</label>
            <div><p-calendar  [(ngModel)]="Horini" [timeOnly]="true" placeholder="Apertura de atencion" readonly>
            </p-calendar></div>
        </div>

        <div class="form-group col-12 col-md-6">
            <label for="Horafin">Hora de Fin de servicio</label>
            <div><p-calendar  [(ngModel)]="Horafin" [timeOnly]="true" placeholder="Cierre de atencion" readonly>
            </p-calendar></div>
        </div>


        <div class="form-group col-12">
            <p-gmap #gmap id="map" [options]="options" [overlays]="overlays" (onMapClick)="handleMapClick($event)"
                (onOverlayClick)="handleOverlayClick($event)" [style]="{'width':'100%','height':'150px'}"></p-gmap>
        </div>

        <div class="btn btn-medisalud-primary"  (click)=" CrearSucursal()">cargar</div>
        <div class="btn btn-medisalud-primary ml-3" *ngIf="Editar == true" (click)=" ActualizarSucursal()">Actualizar
        </div>
        <!-- <div class="btn btn-medisalud-primary" *ngIf="Editar == true" (click)=" Cargar_Nuevamente()">AgregarDoc
        </div> -->
    </div>

    <div class="row">
        <p-table #dt [columns]="cols" [value]="Sucursales" [paginator]="true" [rows]="10" sortMode="multiple"
            [rowHover]="true" dataKey="id" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
            styleClass="ui-table-customers" currentPageReportTemplate="{first} a {last} de {totalRecords} datos"
            [filterDelay]="0" [globalFilterFields]="['sInfoDoc', 'sNomIma', 'sNombre', 'sMail', 'sNumTel', 'sRutDoc']">
            <ng-template pTemplate="caption">
                Sucursales
                <div class="ui-table-globalfilter-container">
                    <input pInputText class="form-control" type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Busqueda" />
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.nombre">
                        {{col.header}}<p-sortIcon [field]="col.nombre"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" (click)="Sucursal_seleccionado(rowData)">
                        <div class="cortar-texto" *ngIf="col.nombre == 'iHini'">
                            {{formathora(rowData["iHini"],rowData["iMini"])}}
                        </div>
                        <div class="cortar-texto" *ngIf="col.nombre == 'iHfin'">
                            {{formathora(rowData["iHfin"],rowData["iMfin"])}}
                        </div>
                        <div class="cortar-texto" *ngIf="col.nombre != 'iHfin' && col.nombre != 'iHini'">
                            {{rowData[col.nombre]}}
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8" style="text-align:left">No se encontraron Sucursales Agregadas.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>