<app-modal *ngIf="CargaCompleta"></app-modal>
<div class="container mt-3">
    <p-toast position="center" key="tc"></p-toast>
    <p-toast [style]="{marginTop: '80px'}"></p-toast>
    <div class="row">

        <div class="form-group col-12 col-md-6">
            <label for="especialidades">Nombre Doctor</label> <span class="text-danger"> {{NombreValidotext}}</span>
            <input maxlength="50" type="text" class="form-control" [(ngModel)]="nombre"
                placeholder="Ingrese nombre del Doctor" (blur)="ComprobarNombre()">
        </div>

        <div class="form-group col-12 col-md-6">
            <label for="especialidades">RUT</label> <span class="text-danger"> {{Rutvalidotext}}</span>
            <input type="text" class="form-control" [(ngModel)]="rut" placeholder="Ingrese RUT del doctor"
                (blur)="comprobarRut()">
        </div>

        <div class="form-group col-12 col-md-6">
            <label for="especialidades">Telefono</label> <span class="text-danger"> {{telefonovalidotext}}</span>
            <input type="text" class="form-control" [(ngModel)]="tel" placeholder="Ingrese Telefono +56912345678"
                (blur)="compexreg_tel()">
        </div>

        <div class="form-group col-12 col-md-6">
            <label for="especialidades">Mail</label> <span class="text-danger"> {{emailvalidotext}}</span>
            <input maxlength="50" type="text" class="form-control" [(ngModel)]="mail"
                placeholder="Ingrese Correo del Doctor" (blur)="compexreg_email()">
        </div>
        <div class="form-group col-12 col-md-6">
            <label for="especialidades">Contraseña</label> <span class="text-danger"> {{pasvalidotext}}</span>
            <input maxlength="20" type="text" class="form-control" [(ngModel)]="pass"
                placeholder="Ingrese Contraseña del Doctor" (blur)="Comprobarpass()">
        </div>
        <div class="form-group col-12 col-sm-6 ">
            <span for="Sexo" class="col-12 col-sm-4 px-0">Habilitar Doctor</span>
            <div class="row mx-0 mt-3">
                <label class="form-check checkbox col-12 col-sm-4">Habilitado
                    <input class="form-check-input" type="radio" name="sexo" id="Habilitado" value="Habilitado"
                        [(ngModel)]="hab">
                    <span class="checkmark"></span>
                </label>
                <label class="form-check checkbox col-12 col-sm-4">Deshabilitado
                    <input class="form-check-input" type="radio" name="sexo" id="Deshabilitado" value="Deshabilitado"
                        [(ngModel)]="hab">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>

        <div class="form-group col-12">
            <label for="especialidades">Foto de Doctor</label>
            <p-fileUpload maxlength="2000" cancelLabel="Cancelar" #fileUpload chooseLabel="Elegir"
                uploadLabel="Guardar Foto" name="myfile[]" customUpload="true"
                (uploadHandler)="uploadFile($event,fileUpload)" accept="image/*"></p-fileUpload>
        </div>

        <div class="form-group col-12 ">
            <label for="especialidades">Info Dctor</label>
            <textarea type="text" class="form-control" [(ngModel)]="inforDoc"
                placeholder="Ingrese descripción profesional del doctor.">
                </textarea>
        </div>
        <img id="img" [src]="imagenurl" [hidden]="true">

        <div class="form-group col-12 col-md-6">
            <div class="btn btn-medisalud-primary" (click)=" AgregarDosctor()">Cargar</div>
            <div class="btn btn-medisalud-primary ml-3" *ngIf="Editar == true" (click)=" ActualizarDosctor()">Actualizar
            </div>
            <!-- <div class="btn btn-medisalud-primary" *ngIf="Editar == true" (click)=" Cargar_Nuevamente()">AgregarDoc
            </div> -->
        </div>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-11 col-md-11">
        <p-table #dt [columns]="cols" [value]="Doctores" [paginator]="true" [rows]="10" sortMode="multiple"
            [rowHover]="true" dataKey="id" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
            styleClass="ui-table-customers" currentPageReportTemplate="{first} a {last} de {totalRecords} datos"
            [filterDelay]="0" [globalFilterFields]="['sNombre', 'sMail', 'sNumTel', 'sRutDoc']">
            <ng-template pTemplate="caption">
                Doctores
                <div class="ui-table-globalfilter-container">
                    <input pInputText class="form-control" type="text"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Busqueda" />
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.nombre">
                        {{col.header}}<p-sortIcon [field]="col.nombre"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" (click)="Doctor_seleccionado(rowData)">
                        <div class="cortar-texto">
                            {{rowData[col.nombre]}}
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8" style="text-align:left">No se encontraron Doctores Agregados.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>