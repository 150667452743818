<app-timeout></app-timeout>
<app-modal  *ngIf="CargaCompleta"></app-modal>

<div class="container-agenda flex-column">
    <br>
    <p-toast position="center" key="tc"></p-toast>
    <div class="row  container mt-5 pb-0">
        <div class="form-group col-12 col">
            <h2 for="RUT">Datos de Cliente</h2>
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="RUT">Rut *</span> <span class="text-danger"> {{Rutvalidotext}}</span>
            <input class="form-control" type="text" (ngModelChange)="formateaRutinpunt()" maxlength="12" [(ngModel)]="RUT" (blur)="CompararCliente()"
                placeholder="Ingrese su RUT">
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="Nombre">Nombre *</span> <span class="text-danger"> {{NombreValidotext}}</span>
            <input (blur)="validarNombre()" class="form-control" type="text" *ngIf="ClienteAntiguo==false || canedit == true" [(ngModel)]="Nombre"
                placeholder="Ingrese su nombre" maxlength="50">
            <input class="form-control" type="text" *ngIf="ClienteAntiguo==true && canedit != true" readonly (click)="UsuarioAntiguo()"
                [(ngModel)]="Nombre" placeholder="Ingrese su nombre">
        </div>
        <!-- <div class="form-group col-12 col-sm-12">
            <span class="text-danger"> {{fechaValidatext}}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
            <div class="row mx-0">
                <span for="fechaN" class="col-12 col-sm-4 px-0">Fecha Nacimiento</span>
                <div class="col-12 col-sm-8 px-0">
                    <p-calendar [(ngModel)]="fechaN" [showIcon]="true" [locale]="es" dateFormat="dd/mm/yy"
                        [monthNavigator]="true" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                        [yearNavigator]="true" (ngModelChange)="ValidarFechacliente()" yearRange="1915:2030" styleClass="medi_calendar"
                        panelStyleClass="medi_calendar--panel" placeholder="Dia/Mes/año"></p-calendar>
                    <p-calendar [(ngModel)]="fechaN" [showIcon]="true" [locale]="es" dateFormat="dd/mm/yy"
                        [monthNavigator]="true" (ngModelChange)="ValidarFechacliente()" *ngIf="ClienteAntiguo==false" [yearNavigator]="true"
                        yearRange="1915:2030" styleClass="medi_calendar" panelStyleClass="medi_calendar--panel"
                        placeholder="Dia/Mes/año"></p-calendar>
                </div>
            </div>
        </div>
        <div class="form-group col-12 col-sm-6 " *ngIf="ClienteAntiguo==true" (click)="UsuarioAntiguo()">
            <div class="row mx-0">
                <span for="Sexo" class="col-12 col-sm-4 px-0">Sexo</span>
                <label class="form-check checkbox col-12 col-sm-4">Masculino
                    <input class="form-check-input" type="radio" name="sexo" id="Masculino" value="Masculino"
                        [(ngModel)]="sexo">
                    <span class="checkmark"></span>
                </label>
                <label class="form-check checkbox col-12 col-sm-4">Femenino
                    <input class="form-check-input" type="radio" name="sexo" id="Femenino" value="Femenino"
                        [(ngModel)]="sexo">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="form-group col-12 col-sm-6 " *ngIf="ClienteAntiguo==false">
            <div class="row mx-0">
                <span for="Sexo" class="col-12 col-sm-4 px-0">Sexo</span>
                <label class="form-check checkbox col-12 col-sm-4">Masculino
                    <input class="form-check-input" type="radio" name="sexo" id="Masculino" value="Masculino"
                        [(ngModel)]="sexo">
                    <span class="checkmark"></span>
                </label>
                <label class="form-check checkbox col-12 col-sm-4">Femenino
                    <input class="form-check-input" type="radio" name="sexo" id="Femenino" value="Femenino"
                        [(ngModel)]="sexo">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="Direccion">Dirección</span>
            <span class="text-danger"> {{DireccionValidatext}}</span>
            <input (blur)="validarDireccion()" class="form-control" type="text" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                [(ngModel)]="Direccion" placeholder="Ingrese su direccion" maxlength="50">
            <input (blur)="validarDireccion()" class="form-control" type="text" *ngIf="ClienteAntiguo==false" [(ngModel)]="Direccion"
                placeholder="Ingrese su direccion">
        </div> -->
        <div class="form-group col-12 col-sm-6">
            <span for="NUmTel">Número de Teléfono</span>
            <span class="text-danger"> {{telefonovalidotext}}</span>
            <input class="form-control" maxlength="12" type="text" *ngIf="ClienteAntiguo==true && canedit != true" readonly (click)="UsuarioAntiguo()"
                [(ngModel)]="NUmTel" (blur)="compexreg_tel()" placeholder="ingrese su telefono +56912345678">
            <input class="form-control" maxlength="12" type="text" *ngIf="ClienteAntiguo==false || canedit == true" [(ngModel)]="NUmTel"
                (blur)="compexreg_tel()" placeholder="ingrese su telefono +56912345678">
        </div>

        <div class="form-group col-12 col-sm-6">
            <span for="Correo">Correo</span>
            <span class="text-danger"> {{emailvalidotext}}</span>
            <input class="form-control" type="text" *ngIf="ClienteAntiguo==true && canedit != true" readonly (click)="UsuarioAntiguo()"
                [(ngModel)]="Correo" (blur)="compexreg_email()" placeholder="Ingrese su correo">
            <input class="form-control" type="text" *ngIf="ClienteAntiguo==false || canedit == true" [(ngModel)]="Correo"
                (blur)="compexreg_email()" placeholder="Ingrese su correo">
        </div>

        <!-- <div class="form-group col-12 col-sm-6">
            <span for="Prevision">Previsión</span>
            <p-dropdown styleClass="w-100" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                [options]="Previciones" [(ngModel)]="Prevision" filter="true" optionLabel="sNombre">
            </p-dropdown>
            <p-dropdown styleClass="w-100" *ngIf="ClienteAntiguo==false" [options]="Previciones" [(ngModel)]="Prevision"
                filter="true" optionLabel="sNombre">
            </p-dropdown>
        </div> -->
        <!-- <div class="form-group col-12 ">
            <div class="custom-control custom-switch" *ngIf="ClienteAntiguo==false">
                <input type="checkbox" class="custom-control-input" id="titular"  [(ngModel)]="titular">
                <label class="custom-control-label" for="titular">¿Es usted carga?</label>
            </div>
            <div class="custom-control custom-switch" *ngIf="ClienteAntiguo==true" (click)="UsuarioAntiguo()">
                <input type="checkbox" class="custom-control-input" id="titular" [(ngModel)]="titular">
                <label class="custom-control-label" for="titular">¿Es usted carga?</label>
            </div>
        </div> -->

        <button class="btn-siguiente ml-auto" (click)="AgregarCliente()">
            <i class="pi pi-arrow-right"></i>
        </button>
    </div>

    <div class="row  container mb-5 pt-0">
        <div class="form-group col-12 col">
            <h2 for="DatoaMascota">Datos de Mascota</h2>
        </div>
        <div class="form-group col-12 col-sm-12" *ngIf="MascotaEncontrada==true">
            <span for="RUT">Mascota</span> <span class="text-danger"> </span>
            <p-dropdown styleClass="w-100" [options]="Mascli" filter="true" optionLabel="sNomMascota"
                (click)="LlenarMascota()" [(ngModel)]="Mascotaselect">
            </p-dropdown>
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="RUT">Nombre</span> <span class="text-danger"> </span>
            <input class="form-control" type="text" [(ngModel)]="NombreMascota"
                placeholder="Ingrese Nombre de su mascota">
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="Tipomas">Tipo Mascota</span>
            <!-- <p-dropdown styleClass="w-100" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                [options]="RazasMasc" [(ngModel)]="RazaMasc" filter="true" optionLabel="sNomRaza">
            </p-dropdown> -->
            <p-dropdown styleClass="w-100" *ngIf="Rutvalido==true" [options]="TioposMAsc" [(ngModel)]="Tipomas"
                filter="true" optionLabel="sNomTipo" (click)="GetRazasXtipo()">
            </p-dropdown>
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="TamaMasc">Tamaño</span>
            <!-- <p-dropdown styleClass="w-100" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                [options]="TamanosMasc" [(ngModel)]="TamaMasc" filter="true" optionLabel="sNomRaza">
            </p-dropdown> -->
            <p-dropdown styleClass="w-100" *ngIf="Rutvalido==true" [options]="TamanosMasc" [(ngModel)]="TamaMasc"
                filter="true" optionLabel="sNomRaza">
            </p-dropdown>
        </div>
        <!-- <div class="form-group col-12 col-sm-6">
            <span for="ColorMasc">Color</span>
            <span class="text-danger"> </span>
 <input class="form-control" type="text" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                [(ngModel)]="ColorMasc" placeholder="Ingrese color de mascota"> 
            <input class="form-control" type="text" *ngIf="Rutvalido==true" [(ngModel)]="ColorMasc"
                placeholder="Ingrese color de mascota" (blur)="validarMascota()">
        </div> -->
        <div class="form-group col-12 col-sm-6">
            <span for="RazaMasc">Raza</span>
            <!-- <p-dropdown styleClass="w-100" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                [options]="RazasMasc" [(ngModel)]="RazaMasc" filter="true" optionLabel="sNomRaza">
            </p-dropdown> -->
            <p-dropdown styleClass="w-100" *ngIf="Rutvalido==true" [options]="RazasMasc" [(ngModel)]="RazaMasc"
                filter="true" optionLabel="sNomRaza">
            </p-dropdown>
        </div>
        <div class="form-group col-12 col-sm-12">
            <span class="text-danger"> </span>
        </div>
        <div class="form-group col-12 col-sm-6">
            <span for="fechaNMascota">Fecha Nacimiento</span>
            <div class="">
                <!-- <p-calendar [(ngModel)]="fechaNMascota" [showIcon]="true" [locale]="es" dateFormat="dd/mm/yy"
                    [monthNavigator]="true" *ngIf="ClienteAntiguo==true" readonly (click)="UsuarioAntiguo()"
                    [yearNavigator]="true" yearRange="1915:2030" styleClass="medi_calendar"
                    panelStyleClass="medi_calendar--panel" placeholder="Dia/Mes/año"></p-calendar> -->
                <p-calendar (ngModelChange)="validarMascota()" [(ngModel)]="fechaNMascota" [showIcon]="true" [locale]="es" dateFormat="dd/mm/yy"
                    [monthNavigator]="true" *ngIf="Rutvalido==true" [yearNavigator]="true" yearRange="1915:2030"
                    styleClass="medi_calendar" panelStyleClass="medi_calendar--panel" placeholder="Dia/Mes/año">
                </p-calendar>
            </div>
        </div>
    </div>
</div>