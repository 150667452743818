<app-modal *ngIf="CargaCompleta"></app-modal>
<div *ngIf="horas">
    <!-- <div style="text-align: right">
        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
        <input type="text" placeholder="Global Filter" [(ngModel)]="filtros" (ngModelChange)="filtrar()"
            style="width:auto">
    </div> -->
    <p-table #dt [columns]="cols" [value]="horas" [paginator]="true" [rows]="10" sortMode="multiple" [rowHover]="true"
        dataKey="id" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" styleClass="ui-table-customers"
        currentPageReportTemplate="{first} a {last} de {totalRecords} datos" [filterDelay]="0"
        [globalFilterFields]="['nomDoc', 'nomEsp', 'fecha', 'hIni', 'hFin', 'fecVen','hIni2','hIni','colacion']">
        <ng-template pTemplate="caption">
            Horarios
            <div class="ui-table-globalfilter-container">
                <input pInputText class="form-control" type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Busqueda" />
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr class="padrenombres">
                <th *ngFor="let col of columns" [pSortableColumn]="col.nombre">
                    {{col.header}}<p-sortIcon [field]="col.nombre"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns; ; let i = index">
                    <div>
                        <span>{{rowData[col.nombre]}}</span>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8" style="text-align:left">Citas no encontradas para esa fecha.</td>
            </tr>
        </ng-template>
    </p-table>
</div>