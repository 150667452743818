import { Component, OnInit } from '@angular/core';
import { MediwebServiceService } from '../../../services/Mediweb/mediweb-service.service';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import * as moment from 'moment'


@Component({
  selector: 'app-aregar-hdoc',
  templateUrl: './aregar-hdoc.component.html',
  styleUrls: ['./aregar-hdoc.component.scss'],
  providers: [MessageService]
})
export class AregarHDocComponent implements OnInit {

  Doctores;
  Sucursusales;
  Especialidades;

  Doctor;
  Sucursal;
  Especialidad;

  hdocsend = "";
  horarios;
  horariosI;
  horariosF;
  horario;

  DiasF;

  horariosxDia;

  horas: any[];
  minutosI: any[];
  minutosF: any[];

  pressdoctor: boolean;
  pressEspecialidad: boolean;
  pressTiempo: boolean;
  pressSucursal: boolean;
  CargaCompleta;

  //Calendario
  es;
  calendarHorario: Date;
  invalidDates: Array<Date> = new Array<Date>();
  worksDate: Array<Date> = new Array<Date>();
  diasagendaAnterior: Array<Date> = new Array<Date>();
  DiasElegidos = [];
  listatooltip = [];
  tolltipshow = "";
  AgregarH = true;
  diaEditar = "";
  //Jornadas
  jornada1HI = {};
  jornada1MI = {};
  jornada1HF = {};
  jornada1MF = {};
  jornada2HI = {};
  jornada2MI = {};
  jornada2HF = {};
  jornada2MF = {};


  constructor(private MediwebServiceService: MediwebServiceService, private Router: Router, private MessageService: MessageService) { }

  ngOnInit(): void {
    this.hdocsend = "";
    this.jornada1HI = { "Hora": "00" }
    this.jornada1MI = { "Minuto": "00" };
    this.jornada1HF = { "Hora": "00" };
    this.jornada1MF = { "Minuto": "00" };
    this.jornada2HI = { "Hora": "00" };
    this.jornada2MI = { "Minuto": "00" };
    this.jornada2HF = { "Hora": "00" };
    this.jornada2MF = { "Minuto": "00" };
    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar',
      dateFormat: 'mm/dd/yy',
    }
    this.CargaCompleta = false;
    this.DiasF = 30;
    var usu = JSON.parse(localStorage.getItem('tipou'));
    if (usu.toString() != "1" && usu.toString() != "2") {
      this.Router.navigate([""]);
      return
    }
    this.GetDoctores();
    this.pressdoctor = false;
    this.pressEspecialidad = false;
    this.pressTiempo = false;
    this.pressSucursal = false;

    this.horarios = [
      { "min": "seleccione" },
      { "min": "10" },
      { "min": "15" },
      { "min": "20" },
      { "min": "25" },
      { "min": "30" },
      { "min": "35" },
      { "min": "40" },
      { "min": "45" },
      { "min": "50" },
      { "min": "55" },
      { "min": "60" }
    ]

    this.horariosI = [
      { "Minuto": "00" },
      { "Minuto": "05" },
      { "Minuto": "10" },
      { "Minuto": "15" },
      { "Minuto": "20" },
      { "Minuto": "25" },
      { "Minuto": "30" },
      { "Minuto": "35" },
      { "Minuto": "40" },
      { "Minuto": "45" },
      { "Minuto": "50" },
      { "Minuto": "55" }
    ]
    this.horariosF = [
      { "Minuto": "seleccione" },
      { "Minuto": "00" },
      { "Minuto": "05" },
      { "Minuto": "10" },
      { "Minuto": "15" },
      { "Minuto": "20" },
      { "Minuto": "25" },
      { "Minuto": "30" },
      { "Minuto": "35" },
      { "Minuto": "40" },
      { "Minuto": "45" },
      { "Minuto": "50" },
      { "Minuto": "55" }
    ]

    this.horariosxDia = [
      { "dia": "Lunes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
      { "dia": "Martes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
      { "dia": "Miercoles", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
      { "dia": "Jueves", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
      { "dia": "Viernes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
      { "dia": "Sabado", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
      { "dia": "Domingo", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false }
    ]

  }
  vercheck(index) {
    //this.horariosxDia[index].Act = !this.horariosxDia[index].Act;
    console.log(this.horariosxDia);

  }

  async changePress(presionado) {
    if (presionado == "D") {
      this.horariosxDia = [
        { "dia": "Lunes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
        { "dia": "Martes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
        { "dia": "Miercoles", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
        { "dia": "Jueves", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
        { "dia": "Viernes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
        { "dia": "Sabado", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
        { "dia": "Domingo", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false }
      ]
      this.horario = undefined;

      this.Sucursal = undefined;
      this.Especialidad = undefined;

      this.pressEspecialidad = false;
      this.pressTiempo = false;
      this.pressSucursal = false;

      this.pressdoctor = true;
    }

    if (presionado == "E") {
      this.pressEspecialidad = true;

      this.horario = undefined;
      this.Sucursal = undefined;

      this.pressTiempo = false;
      this.pressSucursal = false;
    }
    if (presionado == "S") {
      this.invalidDates = new Array<Date>();
      this.worksDate = new Array<Date>();
      this.DiasElegidos = [];
      this.listatooltip = [];
      this.tolltipshow = "";
      this.diaEditar = "";
      this.hdocsend = "";
      //Jornadas
      this.jornada1HI = { "Hora": "00" }
      this.jornada1MI = { "Minuto": "00" };
      this.jornada1HF = { "Hora": "00" };
      this.jornada1MF = { "Minuto": "00" };
      this.jornada2HI = { "Hora": "00" };
      this.jornada2MI = { "Minuto": "00" };
      this.jornada2HF = { "Hora": "00" };
      this.jornada2MF = { "Minuto": "00" };
      await this.OrdenarHorarios();

      //this.GethorarioDoc();


      this.pressTiempo = false;
      this.horario = undefined;
    }
    if (presionado == "T") {
      this.pressTiempo = true;
      console.log(this.horario);

      // this.OrdenarHorarios();
    }

  }

  async GetDoctores() {
    this.CargaCompleta = true;
    var getdoc = { "acc": "D" }
    var respuesta = await this.MediwebServiceService.GetDataGeneral(getdoc);
    await this.GetEspecialidad();
    await this.GetSucursales();
    var primeratributo = respuesta["dataDoc"];
    this.Doctores = [];
    this.Doctores.unshift({ "sNombre": "Seleccione" });
    primeratributo.forEach(element => {
      this.Doctores.push(element);
    });
    console.log(this.Doctores);
    this.Doctor = this.Doctores[0];
    this.Sucursal = this.Sucursusales[0];
    this.Especialidad = this.Especialidades[0];
    //this.OrdenarHorarios();
    this.CargaCompleta = false;
  }

  async GethorarioDoc() {
    this.CargaCompleta = true;
    var gethdoc = {
      "idDoc": this.Doctor["iIdDoc"],
      "idSuc": this.Sucursal["iIdSuc"],
      "idEsp": this.Especialidad["iIdEsp"]
    }
    var contlunes = 0;
    var contmartes = 0;
    var contmiercoles = 0;
    var contjueves = 0;
    var contviernes = 0;
    var contsabado = 0;
    var contdomingo = 0;
    var horarioencontrado = false

    var respuesta = await this.MediwebServiceService.ObtenerHorario(gethdoc);
    console.log(respuesta);
    var horariosDoc = respuesta;
    if (horariosDoc["sHorarios"] != "Sin Datos de Horarios") {
      console.log(horariosDoc);
      this.DiasF = horariosDoc["iAgeDias"];
      var horas = horariosDoc["sHorarios"].split(",");
      console.log(horas);

      this.horario = { "min": horariosDoc["iTpoAte"].toString() };
      horas.forEach(element => {
        console.log(element);

        //agregar lunes
        if (element.includes("aLunes")) {
          horarioencontrado = true;
          contlunes++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Lunes") {
              //{ "dia": "Lunes", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false },
              if (contlunes == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contlunes > 1) {
            this.horariosxDia.splice(contlunes - 1, 0, { "dia": "Lunes", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });

          }
        }

        //Agregar Martes
        if (element.includes("bMartes")) {
          horarioencontrado = true;
          contmartes++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Martes") {
              //{ "dia": "Martes", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false },
              if (contmartes == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contmartes > 1) {
            var contextre = 0;
            if (contlunes == 0) {
              contextre++;
            }
            this.horariosxDia.splice((contlunes + contmartes + contextre) - 1, 0, { "dia": "Martes", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });
          }
        }

        //Agregar Miercoles
        if (element.includes("cMiercoles")) {
          horarioencontrado = true;
          contmiercoles++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Miercoles") {
              //{ "dia": "Miercoles", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false },
              if (contmiercoles == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contmiercoles > 1) {

            var contextre = 0;
            if (contlunes == 0) {
              contextre++;
            }
            if (contmartes == 0) {
              contextre++;
            }
            this.horariosxDia.splice((contlunes + contmartes + contmiercoles + contextre) - 1, 0, { "dia": "Miercoles", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });
          }
        }

        //Agregar Jueves
        if (element.includes("dJueves")) {
          horarioencontrado = true;
          contjueves++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Jueves") {
              //{ "dia": "Jueves", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false },
              if (contjueves == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contjueves > 1) {
            var contextre = 0;
            if (contlunes == 0) {
              contextre++;
            }
            if (contmartes == 0) {
              contextre++;
            }
            if (contmiercoles == 0) {
              contextre++;
            }

            this.horariosxDia.splice((contlunes + contmartes + contmiercoles + contjueves + contextre) - 1, 0, { "dia": "Jueves", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });
          }
        }

        //Agregar Viernes
        if (element.includes("eViernes")) {
          horarioencontrado = true;
          contviernes++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Viernes") {
              //{ "dia": "Viernes", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false },
              if (contviernes == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contviernes > 1) {
            var contextre = 0;
            if (contlunes == 0) {
              contextre++;
            }
            if (contmartes == 0) {
              contextre++;
            }
            if (contmiercoles == 0) {
              contextre++;
            }
            if (contjueves == 0) {
              contextre++;
            }
            this.horariosxDia.splice((contlunes + contmartes + contmiercoles + contjueves + contextre + contviernes) - 1, 0, { "dia": "Viernes", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });
          }
        }

        //Agregar Sabado
        if (element.includes("fSabado")) {
          horarioencontrado = true;
          contsabado++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Sabado") {
              // { "dia": "Sabado", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false }
              if (contsabado == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contsabado > 1) {

            var contextre = 0;

            if (contlunes == 0) {
              contextre++;
            }
            if (contmartes == 0) {
              contextre++;
            }
            if (contmiercoles == 0) {
              contextre++;
            }
            if (contjueves == 0) {
              contextre++;
            }
            if (contviernes == 0) {
              contextre++;
            }
            this.horariosxDia.splice((contlunes + contmartes + contmiercoles + contjueves + contviernes + contsabado + contextre) - 1, 0, { "dia": "Sabado", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });
          }
        }

        //Agregar Domingo
        if (element.includes("gDomingo")) {
          horarioencontrado = true;
          contdomingo++;
          this.horariosxDia.forEach(horariosact => {
            if (horariosact["dia"] == "Domingo") {
              //{ "dia": "Domingo", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false }
              if (contdomingo == 1) {
                horariosact["Act"] = true;
                horariosact["horaI"] = { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] };
                horariosact["minI"] = { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] };
                horariosact["horaF"] = { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] };
                horariosact["minF"] = { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] };
                horariosact["Precar"] = true;
              }
            }
          });
          if (contdomingo > 1) {
            var contextre = 0;

            if (contlunes == 0) {
              contextre++;
            }
            if (contmartes == 0) {
              contextre++;
            }
            if (contmiercoles == 0) {
              contextre++;
            }
            if (contjueves == 0) {
              contextre++;
            }
            if (contviernes == 0) {
              contextre++;
            }
            if (contsabado == 0) {
              contextre++;
            }
            this.horariosxDia.splice((contlunes + contmartes + contmiercoles + contjueves + contviernes + contsabado + contextre + contdomingo) - 1, 0, { "dia": "Domingo", "Act": true, "horaI": { "Hora": element.split(";")[1] < 10 ? "0" + element.split(";")[1] : element.split(";")[1] }, "minI": { "Minuto": element.split(";")[2] < 10 ? "0" + element.split(";")[2] : element.split(";")[2] }, "horaF": { "Hora": element.split(";")[3] < 10 ? "0" + element.split(";")[3] : element.split(";")[3] }, "minF": { "Minuto": element.split(";")[4] < 10 ? "0" + element.split(";")[4] : element.split(";")[4] }, "del": true, "Precar": true });
          }
        }

      });
      console.log(this.horariosxDia);

      this.pressSucursal = true;
      if (horarioencontrado) {
        console.log("HOLA");
        this.changePress('T');
      }
    }
    else {
      this.pressSucursal = true;
    }
    this.CargaCompleta = false;
  }

  async GetSucursales() {
    this.CargaCompleta = true;
    var getSuc = { "acc": "S" }
    var respuesta = await this.MediwebServiceService.GetDataGeneral(getSuc);
    var primeratributo = respuesta["dataSuc"];
    this.Sucursusales = [];
    this.Sucursusales.unshift({ "sNombre": "Seleccione" });
    primeratributo.forEach(element => {
      this.Sucursusales.push(element);
    });
    console.log(this.Sucursusales);
    this.CargaCompleta = false;
  }

  async GetEspecialidad() {
    this.CargaCompleta = true;
    var getEsp = { "acc": "E" }
    var respuesta = await this.MediwebServiceService.GetDataGeneral(getEsp);
    var primeratributo = respuesta["dataEsp"];
    this.Especialidades = [];
    this.Especialidades.unshift({ "sNomEsp": "Seleccione" });
    primeratributo.forEach(element => {
      this.Especialidades.push(element);
    });
    console.log(this.Especialidades);
    this.CargaCompleta = false;
  }

  OrdenarHorarios() {
    setTimeout(() => {
      this.horas = [];
      this.horas.push({ "Hora": "00"});
      for (let index = this.Sucursal["iHini"]; index < (this.Sucursal["iHfin"]); index++) {
        if (index < 10) {
          this.horas.push({ "Hora": "0" + index.toString() });
        }
        else {
          this.horas.push({ "Hora": index.toString() });
        }

      }
      this.TraerHorarios()
      //this.GethorarioDoc();
    }, 200);
  }

  agregardiahoras(dia, pos) {
    this.horariosxDia.splice(pos + 1, 0, { "dia": dia, "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": true, "Precar": false });
  }
  eliminardiahoras(pos) {
    this.horariosxDia.splice(pos, 1);
  }

  async AgregarHorarioDoc() {
    this.CargaCompleta = true;
    // if (this.Direccion == "") {
    //   this.MessageService.clear();
    //   this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe ingresar una direccion para ubicar la sucursal en el mapa' });
    // }

    var newhorarios = "";
    var horasmenores = false;
    this.horariosxDia.forEach(element => {
      console.log(element.horaI.Hora);
      console.log(element.horaF.Hora);

      if (element.horaI.Hora >= element.horaF.Hora && element.Act == true) {
        horasmenores = true;
      }
    });

    this.horariosxDia.forEach(element => {
      console.log(element);
      //{ "dia": "Domingo", "Act": false, "horaI": "00", "minI": "00", "horaF": "00", "minF": "00", "del": false, "Precar":false }
      if (element.Act == true) {
        if (element.dia == "Lunes") {
          newhorarios += "lu," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
        if (element.dia == "Martes") {
          newhorarios += "ma," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
        if (element.dia == "Miercoles") {
          newhorarios += "mi," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
        if (element.dia == "Jueves") {
          newhorarios += "ju," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
        if (element.dia == "Viernes") {
          newhorarios += "vi," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
        if (element.dia == "Sabado") {
          newhorarios += "sa," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
        if (element.dia == "Domingo") {
          newhorarios += "do," + element.horaI.Hora + "," + element.minI.Minuto + "," + element.horaF.Hora + "," + element.minF.Minuto + ";"
        }
      }
    });

    if (newhorarios != "" && !newhorarios.includes("undefined")) {
      if (horasmenores == false) {
        newhorarios = newhorarios.substring(0, newhorarios.length - 1)
        console.log(newhorarios);

        var newhdco = {
          "idDoc": this.Doctor["iIdDoc"],
          "idSuc": this.Sucursal["iIdSuc"],
          "idEsp": this.Especialidad["iIdEsp"],
          "tipAte": parseInt(this.horario["min"]),
          "hab": true,
          "ageDias": this.DiasF,
          "shDoc": newhorarios
        }
        var respuesta = await this.MediwebServiceService.AgregarHorarioDoctor(newhdco);
        console.log(newhdco);
        if (respuesta["message"] == "OK") {
          this.MessageService.clear();
          this.MessageService.add({ key: 'tc', severity: 'success', summary: 'Carga de Horario Correcta', detail: 'Los datos del doctor se han Cargado correctamente' });
          this.Doctor = undefined;
          this.horario = undefined;
          this.Sucursal = undefined;
          this.Especialidad = undefined;
          this.pressEspecialidad = false;
          this.pressTiempo = false;
          this.pressSucursal = false;
          this.pressdoctor = false;
          this.horariosxDia = [
            { "dia": "Lunes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
            { "dia": "Martes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
            { "dia": "Miercoles", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
            { "dia": "Jueves", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
            { "dia": "Viernes", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
            { "dia": "Sabado", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false },
            { "dia": "Domingo", "Act": false, "horaI": { "Hora": "00" }, "minI": { "Minuto": "00" }, "horaF": { "Hora": "00" }, "minF": { "Minuto": "00" }, "del": false, "Precar": false }
          ]
        }
        else {
          this.MessageService.clear();
          this.MessageService.add({ key: 'tc', severity: 'error', summary: 'Error al actualizar', detail: 'Ha ocurrido un error al actualizar los datos: ' + respuesta["message"] });
        }
      }
      else {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Error en horas', detail: 'Las horas de inicio no pueden ser mayor o iguales a la de fin' });
      }

    }
    else {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe ingresar al menos un dia y llenarlo' });
    }

    this.CargaCompleta = false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Metodos de Claendario

  async AgergarEditar(AgrEdt) {
    this.AgregarH = AgrEdt;
    this.pressdoctor = false;
    this.pressEspecialidad = false;
    this.pressTiempo = false;
    this.pressSucursal = false;
    this.invalidDates = new Array<Date>();
    this.worksDate = new Array<Date>();
    this.DiasElegidos = [];
    this.listatooltip = [];
    this.tolltipshow = "";
    this.diaEditar = "";
    this.hdocsend = "";
    this.Doctor = this.Doctores[0];
    //Jornadas
    this.jornada1HI = { "Hora": "00" }
    this.jornada1MI = { "Minuto": "00" };
    this.jornada1HF = { "Hora": "00" };
    this.jornada1MF = { "Minuto": "00" };
    this.jornada2HI = { "Hora": "00" };
    this.jornada2MI = { "Minuto": "00" };
    this.jornada2HF = { "Hora": "00" };
    this.jornada2MF = { "Minuto": "00" };
  }

  checkDateForWork(date: any) {
    var calendarDate = new Date(date.year, date.month, date.day);
    calendarDate.setHours(0, 0, 0, 0);

    return this.isInArray(calendarDate);
  }

  isInArray(value: Date) {
    return !!this.worksDate.find(item => {
      return item.getTime() == value.getTime()
    });
  }

  onSelect($event) {
    console.log("entro", $event);
    let datep = new Date($event);
    var FechaSelect = moment(datep).format("YYYY-MM-DD");
    var fechatoday = moment().format("YYYY-MM-DD");
    var respdiavalido = this.validardiaselect(FechaSelect, fechatoday);
    if (respdiavalido == false) {
      return false;
    }
    if (this.AgregarH == false) {

      for (let index = 0; index < this.worksDate.length; index++) {
        const element = this.worksDate[index];
        console.log(moment(element).format("YYYY-MM-DD"));

        if (moment(element).format("YYYY-MM-DD") == FechaSelect) {
          this.diaEditar = FechaSelect;
          var contjornada = 0;
          for (let index2 = 0; index2 < this.listatooltip.length; index2++) {
            const element2 = this.listatooltip[index2];
            console.log(element2.dia);

            if (element2.dia == this.diaEditar) {
              if (contjornada == 0) {
                var jorna = element2.jornada.split(" a ");
                console.log(this.horas);
                console.log(this.jornada1HI);

                this.jornada1HI = { "Hora": jorna[0].split(":")[0] };
                this.jornada1MI = { "Minuto": jorna[0].split(":")[1] };
                this.jornada1HF = { "Hora": jorna[1].split(":")[0] };
                this.jornada1MF = { "Minuto": jorna[1].split(":")[1] };
              }
              else {
                var jorna = element2.jornada.split(" a ");
                console.log(jorna);

                this.jornada2HI = { "Hora": jorna[0].split(":")[0] };
                this.jornada2MI = { "Minuto": jorna[0].split(":")[1] };
                this.jornada2HF = { "Hora": jorna[1].split(":")[0] };
                this.jornada2MF = { "Minuto": jorna[1].split(":")[1] };
              }
              contjornada++;
            }
          }
        }
      }
      return true;
    }


    this.DiasElegidos.push({ "dia": FechaSelect, "mostrar": true, "previo": false, "jornada1HI": "00", "jornada1MI": "00", "jornada1HF": "00", "jornada1MF": "00", "jornada2HI": "00", "jornada2MI": "00", "jornada2HF": "00", "jornada2MF": "00" });
    var as = this.DiasElegidos;
    var s = as.sort(func);
    function func(a, b) {
      if (a.dia < b.dia) {
        return -1;
      }
      if (a.dia > b.dia) {
        return 1;
      }
      return 0;
    }
    this.DiasElegidos = s;
    console.log(this.DiasElegidos);
    console.log(datep);

    this.worksDate.push(datep);
  }


  validardiaselect(FechaSelect, fechatoday) {
    if (FechaSelect.split("-")[0] == fechatoday.split("-")[0]) {
      if (FechaSelect.split("-")[1] < fechatoday.split("-")[1]) {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia no seleccionable', detail: 'No se puede seleccionar este dia' });
        return false;
      }

      if (parseInt(FechaSelect.split("-")[1]) > parseInt(fechatoday.split("-")[1]) + 1) {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia no seleccionable', detail: 'No se puede seleccionar este dia' });
        return false;
      }

      if (parseInt(FechaSelect.split("-")[1]) == parseInt(fechatoday.split("-")[1]) && parseInt(FechaSelect.split("-")[2]) < parseInt(fechatoday.split("-")[2])) {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia no seleccionable', detail: 'No se puede seleccionar este dia' });
        return false;
      }
    }
    else if (FechaSelect.split("-")[0] > fechatoday.split("-")[0]) {
      if (parseInt(FechaSelect.split("-")[1]) + 12 > parseInt(fechatoday.split("-")[1]) + 1) {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia no seleccionable', detail: 'No se puede seleccionar este dia' });
        return false;
      }
    }
    else if (FechaSelect.split("-")[0] < fechatoday.split("-")[0]) {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia no seleccionable', detail: 'No se puede seleccionar este dia' });
      return false;
    }
    for (let index = 0; index < this.DiasElegidos.length; index++) {
      const element = this.DiasElegidos[index];

      if (element.dia == FechaSelect) {
        this.DiasElegidos.splice(index, 1);
        for (let index2 = 0; index2 < this.diasagendaAnterior.length; index2++) {
          const element2 = this.diasagendaAnterior[index2];
          if (moment(element2).format("YYYY-MM-DD") == FechaSelect) {
            this.MessageService.clear();
            this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia eliminado', detail: 'El dia se ha deseleccionado' });
            return false;
          }
        }
        for (let index2 = 0; index2 < this.worksDate.length; index2++) {
          const element2 = this.worksDate[index2];
          if (moment(element2).format("YYYY-MM-DD") == FechaSelect) {
            this.worksDate.splice(index2, 1);
          }
        }
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Dia eliminado', detail: 'El dia se ha deseleccionado' });
        return false;
      }
    }
  }

  async AgregarHorariosnuevos() {

    var listaeliminados = [];
    console.log(this.jornada1HI);
    if (this.jornada1HI["Hora"] != "00" || this.jornada1HF["Hora"] != "00" || this.DiasElegidos.length > 0) {
      if (this.jornada1HI["Hora"] < this.jornada1HF["Hora"]) {
        for (let index = 0; index < this.DiasElegidos.length; index++) {
          const element = this.DiasElegidos[index];
          var encontrado = false;

          if (this.hdocsend.includes(element.dia)) {
            console.log("dia encontrado");

            if (listaeliminados.length > 0) {
              encontrado = this.buscardiarepetido(listaeliminados, element.dia);
            }
            if (encontrado == false) {
              this.hdocsend = await this.ReeplazarDia(this.hdocsend, element.dia);
              listaeliminados.push(element.dia);
              console.log("reemplazado ", listaeliminados);
              console.log("resultado ", this.hdocsend);

            }

          }
          if (this.jornada1HI["Hora"] != "00" && this.jornada1HF["Hora"] != "00") {
            if (this.hdocsend == "") {
              console.log(this.jornada1MI);

              this.hdocsend += element.dia + "," + this.jornada1HI["Hora"] + "," + this.jornada1MI["Minuto"] + "," + this.jornada1HF["Hora"] + "," + this.jornada1MF["Minuto"];
            }
            else {
              this.hdocsend += ";" + element.dia + "," + this.jornada1HI["Hora"] + "," + this.jornada1MI["Minuto"] + "," + this.jornada1HF["Hora"] + "," + this.jornada1MF["Minuto"];
            }
          }
          if (this.jornada2HI["Hora"] != "00" && this.jornada2HF["Hora"] != "00") {
            this.hdocsend += ";" + element.dia + "," + this.jornada2HI["Hora"] + "," + this.jornada2MI["Minuto"] + "," + this.jornada2HF["Hora"] + "," + this.jornada2MF["Minuto"];
          }
        };
        this.jornada1HI = { "Hora": "00" }
        this.jornada1MI = { "Minuto": "00" };
        this.jornada1HF = { "Hora": "00" };
        this.jornada1MF = { "Minuto": "00" };
        this.jornada2HI = { "Hora": "00" };
        this.jornada2MI = { "Minuto": "00" };
        this.jornada2HF = { "Hora": "00" };
        this.jornada2MF = { "Minuto": "00" };
        this.DiasElegidos = [];
        console.log(this.jornada1MI);

        console.log(this.hdocsend);
        this.diasagendaAnterior = this.worksDate;
      }
      else {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Posible error en horas', detail: 'La hora de fin de joprnada no puede ser menor a la de inicio' });
        return false;
      }
    }
    else {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe ingresar al menos un dia y la primera jornada ' });
      return false;
    }

    this.MessageService.clear();
    this.MessageService.add({ key: 'tc', severity: 'success', summary: 'Horario encolado', detail: 'Se han encolado los horarios a la lista' });
  }

  buscardiarepetido(lista, dia) {
    lista.forEach(element => {
      if (element == dia) {
        return true;
      }
    });
    return false;
  }

  async ReeplazarDia(lista, dia) {
    this.hdocsend = "";
    var DiasAgregados = lista.split(";");
    DiasAgregados.forEach(element => {
      if (element.includes(dia)) {
        element = "";
      }
      else {
        if (this.hdocsend == "") {
          this.hdocsend += element;
        }
        else {
          this.hdocsend += ";" + element;
        }
      }
    });
    return this.hdocsend;
  }

  async AgregarHorariocompleto() {
    this.CargaCompleta = true;
    if (this.horario == undefined) {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe ingresar minutos de atencion' });
      return false;
    }
    if (this.hdocsend == "") {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe agregar al menos un dia al horario' });
      return false;
    }
    var horarios = {
      "idDoc": this.Doctor["iIdDoc"],
      "idSuc": this.Sucursal["iIdSuc"],
      "idEsp": this.Especialidad["iIdEsp"],
      "tipAte": parseInt(this.horario["min"]),
      "ageDias": this.DiasF,
      "hab": true,
      "shDoc": this.hdocsend
    }
    console.log(horarios);

    var resphm = await this.MediwebServiceService.InsertarHorasMedicosXFechas(horarios);
    this.CargaCompleta = false;
    console.log(resphm);
    if (resphm["status"] == true) {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'success', summary: 'Horario agregado', detail: 'Se ha agregado el horario correctamente' });
      this.AgergarEditar(true);
    }
    else {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Error al Agregar', detail: 'Ha ocurrido un error al agregar los datos' });
      return false;
    }

  }

  async TraerHorarios() {
    this.listatooltip = [];
    this.CargaCompleta = true;
    await this.delay(200);

    var gethdoc = {
      "idDoc": this.Doctor["iIdDoc"],
      "idSuc": this.Sucursal["iIdSuc"],
      "idEsp": this.Especialidad["iIdEsp"]
    }
    var resphm = await this.MediwebServiceService.GetHorariosXFech(gethdoc);
    if (resphm["status"] == true) {
      this.DiasF = resphm["iAgeDias"];
      this.horario = { "min": resphm["iTpoAte"] + "" };


      var diahora = resphm["sHorarios"].split(";");
      for (let index = 0; index < diahora.length; index++) {
        const element = diahora[index];
        var date = element.split(",")[0];
        this.worksDate.push(moment(moment(date).format("DD/MM/YYYY"), "DD/MM/YYYY").toDate());
        this.diasagendaAnterior.push(moment(moment(date).format("DD/MM/YYYY"), "DD/MM/YYYY").toDate());
        var horai = element.split(",")[1] < 10 ? "0" + element.split(",")[1] : element.split(",")[1];
        var mini = element.split(",")[2] < 10 ? "0" + element.split(",")[2] : element.split(",")[2];
        var horaf = element.split(",")[3] < 10 ? "0" + element.split(",")[3] : element.split(",")[3];
        var minf = element.split(",")[4] < 10 ? "0" + element.split(",")[4] : element.split(",")[4];
        var diatol = {
          "dia": element.split(",")[0],
          "jornada": horai + ":" + mini + " a " + horaf + ":" + minf
        }
        this.listatooltip.push(diatol)
      }
    }
    console.log(this.worksDate);

    //moment(moment().add(index, 'day').format("DD/MM/YYYY"), "DD/MM/YYYY").toDate();
    this.pressTiempo = true;
    this.CargaCompleta = false;;
    this.pressSucursal = true;;
  }

  async EditarHorario() {
    this.CargaCompleta = true;
    var horarios = {
      "idDoc": this.Doctor["iIdDoc"],
      "idSuc": this.Sucursal["iIdSuc"],
      "idEsp": this.Especialidad["iIdEsp"],
      "sFech": this.diaEditar,
      "sHorarios": ""
    }

    if (this.jornada1HI["Hora"] != "00" && this.jornada1HF["Hora"] != "00") {
      if (this.jornada1HI["Hora"] < this.jornada1HF["Hora"]) {
        horarios["sHorarios"] = this.jornada1HI["Hora"] + "," + this.jornada1MI["Minuto"] + "," + this.jornada1HF["Hora"] + "," + this.jornada1MF["Minuto"];
        if (this.jornada2HI["Hora"] != "00" && this.jornada2HF["Hora"] != "00") {
          if (this.jornada2HI["Hora"] < this.jornada2HF["Hora"]) {
            horarios["sHorarios"] += ";" + this.jornada2HI["Hora"] + "," + this.jornada2MI["Minuto"] + "," + this.jornada2HF["Hora"] + "," + this.jornada2MF["Minuto"];
          }
          else {
            this.MessageService.clear();
            this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Posible error en horas', detail: 'La hora de fin de joprnada no puede ser menor a la de inicio' });
            return false;
          }
        }
      }
      else {
        this.MessageService.clear();
        this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Posible error en horas', detail: 'La hora de fin de joprnada no puede ser menor a la de inicio' });
        return false;
      }

    }

    console.log(horarios);
    var resphm = await this.MediwebServiceService.editarHorasMedicosXFechas(horarios);
    this.CargaCompleta = false;
    if (resphm["status"] == true) {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'success', summary: 'Horario Editado', detail: 'Se ha editado el horario correctamente' });
      this.AgergarEditar(false);
    }
    else {
      this.MessageService.clear();
      this.MessageService.add({ key: 'tc', severity: 'warn', summary: 'Error al Editar', detail: 'Ha ocurrido un error al editar los datos' });
      return false;
    }
  }

  mostraronhover(event) {
    this.tolltipshow = "";
    console.log(event);
    var dia = event.year + "-" + ((event.month + 1) < 10 ? "0" + (event.month + 1) : event.month + 1) + "-" + ((event.day) < 10 ? "0" + event.day : event.day);
    console.log(dia);
    var contjornada = 1;

    for (let index = 0; index < this.listatooltip.length; index++) {
      const element = this.listatooltip[index];
      if (element.dia == dia) {
        this.tolltipshow += "Jornada " + contjornada + " " + element.jornada + " ";
        contjornada++;
      }

    }
  }

}
