<app-timeout></app-timeout>
<app-modal  *ngIf="CargaCompleta"></app-modal>
<div class="container-fluid agenda">
    <div class="row">
        <div class="col-12 col-md-3 box-data">
            <div class="resumen" *ngIf="cliente">
                <div class="title-resumen">
                    <h4>RESUMEN</h4>
                    <p>
                        Datos del Cliente y datos seleccionados
                    </p>
                </div>
                <div class="datos-paciente">
                    <div class="form-group form-text">
                        <label>
                            CLIENTE
                        </label>
                        <span>
                            {{cliente["nom"]}}
                        </span>
                    </div>
                    <div class="form-group form-text">
                        <label>
                            Mascota
                        </label>
                        <span>
                            {{cliente["NombreMasc"]}}
                        </span>
                    </div>
                    <div class="form-group form-text">
                        <label>
                            RUT
                        </label>
                        <span>
                            {{formateaRut(cliente["rutCli"])}}
                        </span>
                    </div>
                    <div class="form-group form-text">
                        <label>
                            Correo
                        </label>
                        <span>
                            {{cliente["mail"]}}
                        </span>
                    </div>
                    <div class="form-group div-outline mt-2">
                        <span  *ngIf="especialidad">{{especialidad["sNomEsp"]}}</span>
                        <i class="pi pi-times "></i>
                    </div>
                    <div class="form-group div-outline">
                        <span  *ngIf="sucursal">{{sucursal["sNombre"]}}</span>
                        <i class="pi pi-times "></i>
                    </div>
                    <div class="form-group div-outline">
                        <span  *ngIf="Doctor">{{Doctor["nomDoc"]}}</span>
                        <i class="pi pi-times "></i>
                    </div>
                    <div class="form-group div-outline">
                        <span  *ngIf="FechaSelect">{{FechaSelect}}</span>
                        <i class="pi pi-times "></i>
                    </div>
                    <div class="form-group  row mx-0">
                        <label class="col-sm-3 col-form-label">HORA:</label>
                        <div class="div-outline col-sm-9">
                            <span  *ngIf="HoraSelect">{{HoraSelect}}</span>
                            <i class="pi pi-times "></i>
                        </div>

                    </div>
                    <div class="form-group" *ngIf="mostrarDetalle == true">
                        <textarea class="form-control" id="nota" rows="5" [(ngModel)]="Descripcion" placeholder="Escriba algun detalle opcional a saber de su mascota para esta cita"></textarea>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 col-md-9 box-selected">
            <p-toast position="top-right" key="tc"></p-toast>
            <div class="row mx-0">
                <div class="col-12 col-md-6 ">
                    <div class="row ">

                        <div class="form-group col-12 col-md-6">
                            <label for="especialidades">Especialidad</label>
                            <p-dropdown [options]="especialidades" [(ngModel)]="especialidad"
                                (onChange)="ActivarAtributos('E',gmap.getMap())" [filter]="true" placeholder="Especialidad"
                                optionLabel="sNomEsp" styleClass="w-100"></p-dropdown>
                        </div>
                        <div *ngIf="SelecEspecialidad" class="form-group  col-12 col-md-6">
                            <label for="sucursales">Sucursales</label>
                            <p-dropdown [options]="sucursales" [(ngModel)]="sucursal" optionLabel="sNombre"
                                (onChange)="ActivarAtributos('S',gmap.getMap())" [filter]="true" placeholder="Sucursal"
                                 styleClass="w-100"></p-dropdown>
                        </div>
                        <div *ngIf="SelecSucursal" class="form-group col-12">
                            <label for="Medico">Medico</label>
                            <p-dropdown [options]="medico" [(ngModel)]="Doctor" optionLabel="nomDoc"
                             (ngModelChange)="ActivarAtributos('D',gmap.getMap())"  placeholder="Medico"  styleClass="w-100">
                                <ng-template let-car pTemplate="item">
                                    <div class="ui-helper-clearfix"
                                        style="position: relative;height:50px;padding-left: 60px;">
                                        <img [src]="car['value']['sNomIma']" class="user_icon" />
                                        <div class="inf-User">
                                            <div class="nombre">{{car.label}}</div>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <!-- <div *ngIf="SelecDoctor">
                            <span>Fecha Nacimiento</span>
                            <p-calendar (ngModelChange)="ActivarAtributos('F')" [(ngModel)]="fechaA" [showIcon]="true"
                                [locale]="es" dateFormat="dd/mm/yy"></p-calendar>
                        </div>
                        <div *ngIf="SelecFechaA" (change)="ActivarAtributos('H')">
                            <span>Horario</span>
                            <select [(ngModel)]="horario">
                                <option>12:00 - 12:30</option>
                                <option>12:30 - 1:00</option>
                            </select>
                        </div> -->
                        <div class="col-12" *ngIf="SelecDoctor">
                            <div class="row">
                                <div class="col-3">
                                    <div>
                                        <img [src]="ImagenUrl" class="selectuser user_icon w-100" />
                                    </div>
                                </div>
                                <div class="col-9 user_selected">
                                    <h3>{{Doctor["nomDoc"]}}</h3>
                                    <h5>{{especialidad["sNomEsp"]}} </h5>
                                    <p>
                                        {{Doctor["infoDoc"]}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <p-gmap #gmap id="map" [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'150px'}"></p-gmap>
                        </div>
                        <div class="col-12 mt-2" *ngIf="es">
                            <p-calendar [(ngModel)]="calendarHorario" [locale]="es" [inline]="true"
                                styleClass="medi_calendar" panelStyleClass="medi_calendar--panel" dateFormat="dd/mm/yy"
                                [disabledDates]="invalidDates" [monthNavigator]="true" [yearNavigator]="true"
                                yearRange="2021:2050" (onSelect)="onSelect($event)">
                                <ng-template pTemplate="date" let-date>
                                    <div *ngIf="checkDateForWork(date);then workDate else normalDate"></div>
                                    <ng-template #workDate>
                                        <span [ngClass]="'workday'" style="display:block">{{date.day}}</span>
                                    </ng-template>
                                    <ng-template #normalDate>
                                        <span [ngClass]="'inherit'" style="display:block">{{date.day}}</span>
                                    </ng-template>

                                </ng-template>
                                <!-- <ng-template pTemplate="date" let-date>
                                    <span [ngClass]=" (date.day < 21 && date.day > 10) ? 'workday' : 'inherit'" style="display:block">{{date.day}}</span>
                                </ng-template> -->
                            </p-calendar>
                            <div class="row mx-0  justify-content-end" *ngIf="Horas">
                                <div class="indicators" *ngIf="Horas.length >0">
                                    <div class="circle-indicator libre"></div>
                                    <span>Libre</span>
                                </div>
                                <div class="indicators" *ngIf="Horas.length >0">
                                    <div class="circle-indicator seleccionada"></div>
                                    <span>Seleccionada</span>
                                </div>
                                <div class="indicators" *ngIf="Horas.length >0">
                                    <div class="circle-indicator ocupada"></div>
                                    <span>Ocupada</span>
                                </div>
                            </div>

                            <div class="InputGroup row mx-0 mt-2" *ngIf="Horas">
                                <div *ngIf="Horas.length == 0 && horaselect == true">Lo sentimos no hay mas horas disponibles para hoy</div>
                                <div class="px-2" *ngFor="let hora of Horas let index = index">
                                    <input type="radio" name="size" id="{{index}}" value="small" *ngIf="hora.disp == true" />
                                    <input type="radio" name="size" id="{{index}}" value="small" *ngIf="hora.disp == false" class="full" disabled/>
                                    <label for="{{index}}" (click)="HoraSelect = hora.Hora;" class="hour_label"  >{{hora.Hora}}</label>
                                </div>
                                <!-- <div class="px-2">
                                    <input type="radio" name="size" id="size_1" value="small" disabled />
                                    <label for="size_1" class="hour_label">10:00</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_2" value="small" disabled />
                                    <label for="size_2" class="hour_label">10:30</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_3" value="small" />
                                    <label for="size_3" class="hour_label">11:00</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_4" value="small" />
                                    <label for="size_4" class="hour_label">11:30</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_5" value="small" />
                                    <label for="size_5" class="hour_label">12:00</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_6" value="small" class="full" />
                                    <label for="size_6" class="hour_label">12:30</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_7" value="small" class="full" />
                                    <label for="size_7" class="hour_label">13:00</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_8" value="small" />
                                    <label for="size_8" class="hour_label">13:30</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_9" value="small" />
                                    <label for="size_9" class="hour_label">14:00</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_10" value="small" checked />
                                    <label for="size_10" class="hour_label">14:30</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_12" value="small" disabled />
                                    <label for="size_12" class="hour_label">15:00</label>
                                </div>
                                <div class="px-2">
                                    <input type="radio" name="size" id="size_13" value="small" class="full" />
                                    <label for="size_13" class="hour_label">15:30</label>
                                </div> -->
                            </div>
                            <div class="row mx-0 ">
                                <div class="col-12 px-0" (click)="AgregarCita()">
                                    <button class="btn-siguiente ml-auto">
                                        <i class="pi pi-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</div>