<app-modal *ngIf="CargaCompleta"></app-modal>
<div class="container mt-3">
    <div class="row">
        <p-toast position="top-right" key="tc"></p-toast>
        <div class="form-group col-12">
            <div class="col-6 btn btn-info" (click)="AgergarEditar(true)">Agrear Horario</div>
            <div class="col-6 btn btn-info" (click)="AgergarEditar(false)">Editar Horario</div>
        </div>
        <div class="form-group col-12 col-md-12">
            <h3 for="Doctores"[hidden]=" AgregarH==false">Agregar Horarios</h3>
            <h3 for="Doctores"[hidden]=" AgregarH==true">Editar Horarios</h3>
        </div>
        
        <div class="form-group col-12 col-md-6">
            <label for="Doctores">Doctores</label>
            <p-dropdown [options]="Doctores" (ngModelChange)="changePress('D')" [(ngModel)]="Doctor" filter="true"
                optionLabel="sNombre" styleClass="w-100"></p-dropdown>
        </div>

        <div [hidden]="!pressdoctor" class="form-group col-12 col-md-6">
            <label for="Especialidades">Especialidad</label>
            <p-dropdown [options]="Especialidades" (ngModelChange)="changePress('E')" [(ngModel)]="Especialidad"
                filter="true" optionLabel="sNomEsp" styleClass="w-100"></p-dropdown>
        </div>

        <div [hidden]="!pressEspecialidad" class="form-group col-12 col-md-6">
            <label for="Sucursusales">Sucursusales</label>
            <p-dropdown [options]="Sucursusales" (ngModelChange)="changePress('S')" [(ngModel)]="Sucursal" filter="true"
                optionLabel="sNombre" styleClass="w-100"></p-dropdown>
        </div>

        <div [hidden]="!pressSucursal" class="form-group col-12 col-md-6">
            <label for="horarios">Minutos de consulta</label>
            <p-dropdown [options]="horarios" (ngModelChange)="changePress('T')" [(ngModel)]="horario" optionLabel="min"
                styleClass="w-100">
            </p-dropdown>
            <!-- //<input type="number" min="9" max="15">:<input type="number" min="0" max="59"> -->
        </div>

        <!-- <div [hidden]="!pressSucursal" class="form-group col-12 col-md-6">
            <label for="horarios">Dias Maximos a Agendar: </label>
            <input type="number" class="form-control" [(ngModel)]="DiasF" min="1" max="366" styleClass="w-100">
            <!-- //<input type="number" min="9" max="15">:<input type="number" min="0" max="59"> 
        </div> -->



        <div *ngIf="pressTiempo" class="form-group col-12 ">
            <!-- <label for="Horarios">Horarios</label><br>
            <div *ngFor="let item of horariosxDia;let i=index" class="col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-field p-col-2 d-flex">
                        <p-checkbox name="groupname" [binary]="true" [(ngModel)]="item.Act" (click)="vercheck(i)"
                            label="{{item.dia}}"></p-checkbox>

                        <div class="mashora" (click)="agregardiahoras(item.dia,i)"><i class="pi pi-plus"></i></div>
                        <div *ngIf="item.del == true" class="mashora borrar" (click)="eliminardiahoras(i)"><i
                                class="pi pi-times"></i></div>
                    </div>
                    <div class="p-field p-grid p-col">
                        <label for="horas" class="p-col-fixed">Hora Inicio: </label>
                        <div class="p-col">
                            <p-dropdown [options]="horas" [(ngModel)]="item.horaI" placeholder="Seleccione"
                                optionLabel="Hora" [disabled]="item.Act == false">
                            </p-dropdown>
                        </div>
                        <label for="horariosI" class="p-col-fixed">:</label>
                        <div class="p-col">
                            <p-dropdown [options]="horariosI" [(ngModel)]="item.minI" placeholder="Seleccione"
                                optionLabel="Minuto" [disabled]="item.Act == false">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-field p-grid p-col">
                        <label for="horas" class="p-col-fixed">Hora Fin: </label>
                        <div class="p-col">
                            <p-dropdown [options]="horas" [(ngModel)]="item.horaF" placeholder="Seleccione"
                                optionLabel="Hora" [disabled]="item.Act == false">
                            </p-dropdown>
                        </div>
                        <label for="horariosF" class="p-col-fixed">:</label>
                        <div class="p-col">
                            <p-dropdown [options]="horariosF" [(ngModel)]="item.minF" placeholder="Seleccione"
                                optionLabel="Minuto" [disabled]="item.Act == false">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div> -->


            <div class="card" style="width: 100%;">
                <div class="card-body">
                    <h3 [hidden]="AgregarH==true" class="card-title">Editar Horario de los doctores</h3>
                    <p [hidden]="AgregarH==true" class="card-text">seleccione el dia que necesite modificar</p>
                    <h3 [hidden]="AgregarH==false" class="card-title">Agregar Horario de los doctores</h3>
                    <p [hidden]="AgregarH==false" class="card-text">Seleccione los dias que compartan horarios en comun</p>

                    <div class="row">
                        <div class="col-6">
                            <p-calendar [(ngModel)]="calendarHorario" [locale]="es" [inline]="true"
                                styleClass="medi_calendar" panelStyleClass="medi_calendar--panel" dateFormat="dd/mm/yy"
                                [disabledDates]="invalidDates" [monthNavigator]="true" [yearNavigator]="true"
                                yearRange="2021:2050" (onSelect)="onSelect($event)" >
                                <ng-template pTemplate="date" let-date>
                                    <div *ngIf="checkDateForWork(date);then workDate else normalDate"></div>
                                    <ng-template #workDate>
                                        <span [ngClass]="'workday'" (mouseover)="mostraronhover(date)" data-toggle="tooltip" data-placement="top" title="{{tolltipshow}}" style="display:block">{{date.day}}</span>
                                    </ng-template>
                                    <ng-template #normalDate>
                                        <span [ngClass]="'inherit'"  style="display:block">{{date.day}}</span>
                                    </ng-template>
                                </ng-template>
                            </p-calendar>
                        </div>
                        <div class="col-6">
                            <div>Dias seleccionados</div>
                            <div class="d-flex flex-wrap" [hidden]="!pressTiempo || AgregarH==false">
                                <div style="width: 6rem;" *ngFor="let item of DiasElegidos;let i=index" class="mt-3 mr-2 text-center">
                                    <div class="card">
                                        <div><span>{{item.dia}}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap" [hidden]="!pressTiempo || AgregarH==true">
                                <div style="width: 6rem;" class="mt-3 mr-2 text-center">
                                    <div class="card">
                                        <div><span>{{this.diaEditar}}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div>Jornadas</div>
                            <div>
                                <div class="jornada">
                                    <span> Jornada 1
                                        <p-dropdown [options]="horas" [(ngModel)]="jornada1HI"
                                            placeholder="00" optionLabel="Hora">
                                        </p-dropdown>
                                        <p-dropdown [options]="horariosI" [(ngModel)]="jornada1MI"
                                            placeholder="00" optionLabel="Minuto">
                                        </p-dropdown>
                                    </span>
                                    <span>a</span>
                                    <span>
                                        <p-dropdown [options]="horas" [(ngModel)]="jornada1HF"
                                            placeholder="00" optionLabel="Hora">
                                        </p-dropdown>
                                        <p-dropdown [options]="horariosI" [(ngModel)]="jornada1MF"
                                            placeholder="00" optionLabel="Minuto">
                                        </p-dropdown>
                                    </span>
                                </div>
                                <div class="jornada">
                                    <span> Jornada 2
                                        <p-dropdown [options]="horas" [(ngModel)]="jornada2HI"
                                            placeholder="00" optionLabel="Hora">
                                        </p-dropdown>
                                        <p-dropdown [options]="horariosI" [(ngModel)]="jornada2MI"
                                            placeholder="00" optionLabel="Minuto">
                                        </p-dropdown>
                                    </span>
                                    <span>a</span>
                                    <span>
                                        <p-dropdown [options]="horas" [(ngModel)]="jornada2HF"
                                            placeholder="00" optionLabel="Hora">
                                        </p-dropdown>
                                        <p-dropdown [options]="horariosI" [(ngModel)]="jornada2MF"
                                            placeholder="00" optionLabel="Minuto">
                                        </p-dropdown>
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <br>
                    <a class="btn btn-medisalud-primary" (click)="AgregarHorariosnuevos()" [hidden]="!pressTiempo || AgregarH==false">Añadir Horario</a>
                    <a class="btn btn-medisalud-primary" (click)="EditarHorario()" [hidden]="!pressTiempo || AgregarH==true">Editar Horario</a>
                </div>
            </div>
        </div>

        <div class="btn btn-medisalud-primary" (click)="AgregarHorariocompleto()" [hidden]="!pressTiempo || AgregarH==false">
            Insertar Horario
        </div>
    </div>
</div>