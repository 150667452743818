<div class="row  distbtn" *ngIf="usu.toString() == '1' ">
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Cliente-Agenda">
        Agenda Cliente
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/EditCliente">
        Editar Cliente
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Especilidad">
        Agregar especialidad
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/CitaRotatoria">
        Citas Rotatorias / Sobrecupo
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/AdmCita">
        Administrar citas
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Sucursal">
        Agregar Sucursal
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Doctor">
        Agregar Doctor
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/HorarioDoctor">
        Horarios Doctor
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Horasmedicas">
        Lista Horarios Doctor
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Raza">
        Razas
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/TipoMascota">
        Tipo Mascota
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Cancelacion">
        Cancelar Citas
    </button>
</div>

<div class="row  distbtn" *ngIf="usu.toString() == '2' ">
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Cliente-Agenda">
        Agenda Cliente
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/CitaRotatoria">
        Citas Rotatorias / Sobrecupo
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/AdmCita">
        Administrar citas
    </button>
    <button class="btn btn-medisalud-primary mt-2" routerLink="/Horasmedicas">
        Lista Horarios Doctor
    </button>
</div>