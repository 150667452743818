import { Component, OnInit } from '@angular/core';
import * as moment from 'moment'
import { MediwebServiceService } from '../../../services/Mediweb/mediweb-service.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
interface City {
  name: string;
  code: string;
}
@Component({
  selector: 'app-datos-agenda',
  templateUrl: './datos-agenda.component.html',
  styleUrls: ['./datos-agenda.component.scss'],
  providers: [MessageService]
})

export class DatosAgendaComponent implements OnInit {

  especialidades;
  sucursales;
  medico;
  selectedEsp: City;
  selectedSuc: City;
  selectedMed;
  calendarHorario: Date;
  invalidDates: Array<Date> = new Array<Date>();
  worksDate: Array<Date> = new Array<Date>();

  cliente

  especialidad: string;
  sucursal: string;
  Doctor: string;
  fechaA: Date;
  horario: string;
  es: any;
  Horas: any;

  ImagenUrl: string;

  SelecEspecialidad: boolean;
  SelecSucursal: boolean;
  SelecDoctor: boolean;
  SelecFechaA: boolean;
  SelecHorario: boolean;
  options: any;
  FechaSelect;
  HoraSelect;
  Descripcion;
  mostrarDetalle;

  overlays: any[];
  Clintes;

  horaselect = false;

  CargaCompleta;

  url;

  constructor(private MediwebServiceService: MediwebServiceService, private messageService: MessageService, private Router: Router) {
  }

  async ngOnInit() {
    localStorage.setItem('tipou', JSON.stringify(3));
    this.url = await this.MediwebServiceService.getConfig();
    this.mostrarDetalle = false;
    this.cliente = JSON.parse(localStorage.getItem('Cliente'));
    console.log(this.cliente);
    this.CargaCompleta = true;
    this.traerEspecialidad();
    this.GetClientes();
    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 12
    };

    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Borrar',
      dateFormat: 'mm/dd/yy',
    }

    this.SelecEspecialidad = false;
    this.SelecSucursal = false;
    this.SelecDoctor = false;
    this.SelecFechaA = false;
    this.SelecHorario = false;
  }

  ActivarAtributos(tipo, map) {
    this.mostrarDetalle = false;
    this.horaselect = false;
    setTimeout(() => {
      if (tipo == "E") {
        this.SelecEspecialidad = true;
        this.SelecSucursal = false;
        this.SelecDoctor = false;

        this.sucursales = undefined;
        this.medico = undefined;
        this.worksDate = [];
        this.Horas = [];

        this.sucursal = undefined;
        this.Doctor = undefined;
        this.FechaSelect = undefined;
        this.HoraSelect = undefined;


        this.traerSucursales();
      }
      if (tipo == "S") {
        this.SelecSucursal = true;
        this.SelecDoctor = false;

        this.medico = undefined;
        this.worksDate = [];
        this.Horas = [];

        this.Doctor = undefined;
        this.FechaSelect = undefined;
        this.HoraSelect = undefined;

        var Latitud: number = parseFloat(this.sucursal["slati"]);
        var Longitud: number = parseFloat(this.sucursal["slong"]);

        console.log(Latitud);
        console.log(Longitud);



        map.setCenter({ lat: Latitud, lng: Longitud });
        map.setZoom(14);
        this.overlays = [new google.maps.Marker({ position: { lat: Latitud, lng: Longitud }, title: "mapa", draggable: false })];

        this.traerDoctores();
      }
      if (tipo == "D") {

        console.log(this.Doctor);
        this.ImagenUrl = this.Doctor["sNomIma"];
        this.SelecDoctor = true;
        this.worksDate = [];
        this.Horas = [];

        this.FechaSelect = undefined;
        this.HoraSelect = undefined;
        this.CargaCompleta = true;
        this.obtenerDiasDetrabajo();
        this.mostrarDetalle = true;
      }
      if (tipo == "F") {
        this.SelecFechaA = true;
      }
      if (tipo == "H") {
        this.SelecHorario = true;
      }
    }, 300);

  }


  checkDateForWork(date: any) {
    var calendarDate = new Date(date.year, date.month, date.day);
    calendarDate.setHours(0, 0, 0, 0);

    return this.isInArray(calendarDate);
  }

  isInArray(value: Date) {
    return !!this.worksDate.find(item => {
      return item.getTime() == value.getTime()
    });
  }

  async traerEspecialidad() {
    var getcli = {
      "acc": "E"
    }
    this.CargaCompleta = true;
    var respuesta = await this.MediwebServiceService.GetDataGeneral(getcli);
    this.CargaCompleta = false;
    if (respuesta["status"]) {
      this.especialidades = respuesta["dataEsp"];
      console.log(this.especialidades);
    }
  }

  async traerSucursales() {
    var req = {
      "acc": "S",
      "idEsp": this.especialidad["iIdEsp"],
      "idSuc": 0
    }
    console.log(req);
    this.CargaCompleta = true;
    var respuesta = await this.MediwebServiceService.ConsDetCitDoc(req);
    this.CargaCompleta = false;
    if (respuesta["status"]) {
      var sucursales = respuesta["datS"];
      this.sucursales = sucursales;
      console.log(this.sucursales);
    }
  }

  async traerDoctores() {
    var req = {
      "acc": "D",
      "idEsp": this.especialidad["iIdEsp"],
      "idSuc": this.sucursal["idSuc"]
    }
    console.log(req);
    this.CargaCompleta = true;
    var respuesta = await this.MediwebServiceService.ConsDetCitDoc(req);
    this.CargaCompleta = false;
    console.log(respuesta);

    if (respuesta["status"]) {
      var doctores = respuesta["datD"];
      this.medico = doctores;
      this.medico.forEach(element => {
        element["sNomIma"] = this.url["Url_Imagen"] + element["nomIma"] + ".jpg"
      });
      var as = this.medico;
      var s = as.sort(func);

      function func(a, b) {
        return 0.5 - Math.random();
      }
      this.medico = s;
      console.log(this.medico);
    }
  }

  async GetClientes() {
    var getcli = {
      "rut": this.cliente["rutCli"]
    }
    this.CargaCompleta = true;
    var respuesta = await this.MediwebServiceService.TraerClienteRut(getcli);
    this.CargaCompleta = false;
    this.Clintes = respuesta;
    console.log(this.Clintes);
  }

  async obtenerDiasDetrabajo() {

    var datodoc = {
      "idDoc": this.Doctor["iddoc"],
      "idEsp": this.especialidad["iIdEsp"],
      "idSuc": this.sucursal["idSuc"]
    }
    var horariosdoc = await this.MediwebServiceService.GetHorariosAgenda(datodoc);
    console.log(horariosdoc);
    this.CargaCompleta = false;

    if (horariosdoc["status"]) {
      this.Doctor["iAgeDias"] = horariosdoc["iAgeDias"];
      this.Doctor["horAte"] = horariosdoc["iTpoAte"];
      this.Doctor["horCon"] = horariosdoc["sHorarios"];
      this.Doctor["horOcu"] = horariosdoc["sHorOcu"];
      this.Doctor["diamax"] = "0";
    }

    this.worksDate = [];
    var hoy = moment().format("DD/MM/YYYY");
    var mes = moment().format("MMM");
    console.log(moment(moment().add(5, 'day').format("DD/MM/YYYY")).toDate());

    var cantdias = this.Doctor["horCon"].split(";")
    for (let index = 0; index < cantdias.length; index++) {
      const element = cantdias[index];
      var date = element.split(",")[0];
      this.Doctor["diamax"] = date;
      this.worksDate.push(moment(moment(date).format("DD/MM/YYYY"), "DD/MM/YYYY").toDate());
      //for (let index = 0; index < 5; index++) {
    }
  }

  onSelect($event) {
    this.Horas = [];
    this.HoraSelect = undefined;
    console.log("entro", $event);

    let day = new Date($event).getDate();
    let month = new Date($event).getMonth();
    let year = new Date($event).getFullYear();
    month += 1;
    console.log(year);
    console.log(month);
    console.log(day);
    let diaelegido = month + "/" + day + "/" + year;
    this.FechaSelect = moment(diaelegido).format("YYYY-MM-DD");
    if (this.FechaSelect < moment().format("YYYY-MM-DD")) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'El dia elegido debe ser mayor a la fecha actual' });
      return;
    }
    console.log(this.Doctor["diamax"])
    if (this.FechaSelect > this.Doctor["diamax"]) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'El dia elegido debe ser menor a las fechas adminitidas' });
      return;
    }

    let newday = moment(moment(diaelegido).format("DD/MM/YYYY"), "DD/MM/YYYY").toDate();
    console.log(moment(newday).format("dddd"));
    this.horaselect = false;
    if (moment(newday).format("dddd") == "Monday") {
      this.calcularHorarios("Lunes", this.FechaSelect);
    }
    if (moment(newday).format("dddd") == "Tuesday") {
      this.calcularHorarios("Martes", this.FechaSelect);
    }
    if (moment(newday).format("dddd") == "Wednesday") {
      this.calcularHorarios("Miercoles", this.FechaSelect);
    }
    if (moment(newday).format("dddd") == "Thursday") {
      this.calcularHorarios("Jueves", this.FechaSelect);
    }
    if (moment(newday).format("dddd") == "Friday") {
      this.calcularHorarios("Viernes", this.FechaSelect);
    }
    if (moment(newday).format("dddd") == "Saturday") {
      this.calcularHorarios("Sabado", this.FechaSelect);
    }
    if (moment(newday).format("dddd") == "Sunday") {
      this.calcularHorarios("Domingo", this.FechaSelect);
    }
  }
  calcularHorarios(dia, fecha) {
    var dias = this.Doctor["horCon"].split(";");
    this.Horas = [];
    dias.forEach(element => {
      if (fecha == element.split(",")[0]) {
        var horaI = parseInt(element.split(",")[1]);
        var minI = parseInt(element.split(",")[2]);
        var horaF = parseInt(element.split(",")[3]);
        var minF = parseInt(element.split(",")[4]);
        console.log("horas tomasdas");

        console.log(horaI);
        console.log(minI);
        console.log(horaF);
        console.log(minF);
        var horaFinal = horaF + ":" + minF;
        var Mmin = minI - minF;
        if (Mmin < 0) {
          Mmin = Mmin * -1;
        }
        Mmin = Mmin - parseInt(this.Doctor["horAte"]);
        var mintot = ((horaF - horaI) * 60) + Mmin;
        console.log(mintot);

        var CantHoras = mintot / parseInt(this.Doctor["horAte"]);
        console.log(CantHoras);

        var HoraS = horaI + ":" + minI + ":" + "00";
        for (let index = 0; index < CantHoras; index++) {
          if (index == 0) {
            var primerminuto = (moment(HoraS, 'HH:mm:ss').format('HH:mm'));
            let Fbusqueda = fecha + " " + primerminuto;
            let minutos = (moment(HoraS, 'HH:mm:ss').add(parseInt(this.Doctor["horAte"]), 'minutes').format('HH:mm'));
            console.log(Fbusqueda);
            console.log("HOY " + moment().format('YYYY-MM-DD'));
            console.log("FEHCA A COMPARAR " + fecha);
            console.log("hora actual " + (parseInt(moment().format('HH'))));
            console.log("hora a comprar " + (parseInt(moment(HoraS, 'HH:mm:ss').format('HH'))));
            //(moment().format('YYYY-MM-DD') == fecha) && (parseInt(moment(HoraS).format('HH'))) < (parseInt(moment().format('HH')) )
            if ((moment().format('YYYY-MM-DD') == fecha) && (parseInt(moment(HoraS, 'HH:mm:ss').format('HH'))) < (parseInt(moment().format('HH'))) - 1) {

            }
            else {
              if (this.Doctor["horOcu"]) {
                if (this.Doctor["horOcu"].includes(Fbusqueda)) {
                  this.Horas.push({ "Hora": primerminuto, "disp": false })
                }
                else {
                  this.Horas.push({ "Hora": primerminuto, "disp": true })
                }
              }
              else {
                this.Horas.push({ "Hora": primerminuto, "disp": true })
              }
            }
          }

          let minutos = (moment(HoraS, 'HH:mm:ss').add(parseInt(this.Doctor["horAte"]), 'minutes').format('HH:mm'));
          let Fbusqueda = fecha + " " + minutos;
          console.log(Fbusqueda);

          console.log("hora", moment().format('HH:mm'));
          console.log("hora com", moment(minutos, 'HH:mm').format('HH:mm'));
          if (minutos < horaFinal) {
            if ((moment().format('YYYY-MM-DD') == fecha) && (parseInt(moment(HoraS, 'HH:mm:ss').format('HH'))) < (parseInt(moment().format('HH')) - 1)) {

            }
            else {
              if (this.Doctor["horOcu"]) {
                if (this.Doctor["horOcu"].includes(Fbusqueda)) {
                  this.Horas.push({ "Hora": minutos, "disp": false })
                }
                else {
                  this.Horas.push({ "Hora": minutos, "disp": true })
                }
              }
              else {
                this.Horas.push({ "Hora": minutos, "disp": true })
              }
            }
          }
          HoraS = minutos;
        }
      }
    });
    if (this.Horas.length == 0) {
      this.horaselect = true;
    }
    for (let index = 0; index < this.Horas.length; index++) {
      const element = this.Horas[index];
      console.log("Antes", element);
      if (element["Hora"] < moment().format('HH:mm') && moment().format('YYYY-MM-DD') == fecha) {
        this.Horas.splice(index, 1);
        index--;
      }
    }
    console.log(this.Horas);


  }


  async AgregarCita() {

    if (this.especialidad == undefined) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe Seleccionar una especialidad' });
    }
    else if (this.sucursal == undefined) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe Seleccionar una Sucursal' });
    }
    else if (this.Doctor == undefined) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe Seleccionar un Doctor' });
    }
    else if (this.FechaSelect == undefined) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe Seleccionar un dia para cita' });
    }
    else if (this.FechaSelect < moment().format("YYYY-MM-DD")) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'El dia elegido debe ser mayor a la fecha actual' });
    }
    else if (this.HoraSelect == undefined) {
      this.messageService.clear();
      this.messageService.add({ key: 'tc', severity: 'warn', summary: 'Faltan datos por llenar', detail: 'Debe Seleccionar una hora para cita' });
    }
    else {

      var clienteSelect
      console.log(this.Clintes);

      clienteSelect = this.Clintes;

      var req = {
        "idCli": clienteSelect["idCli"],
        "idMas": this.cliente["IdMasc"],
        "idSuc": this.sucursal["idSuc"],
        "idDoc": this.Doctor["iddoc"],
        "idPre": 0,
        "idEsp": this.especialidad["iIdEsp"],
        "fecAge": this.FechaSelect.toString() + "T" + this.HoraSelect.toString() + ":00.279Z",
        "datoCita": this.Descripcion != undefined ? this.Descripcion : "",
        "camCli": this.cliente["camCli"]
      }
      // {
      //   "idCita": "1",
      //   "idCliente": clienteSelect["iIdCli"] + "",
      //   "idSucursal": this.sucursal["iIdSuc"].toString(),
      //   "idDoctor": this.Doctor["iddoc"].toString(),
      //   "idEsp": this.especialidad["iIdEsp"].toString(),
      //   "idPrev": clienteSelect["iIdPrev"] + "",
      //   "fecha": this.FechaSelect.toString(),
      //   "hora": this.HoraSelect.toString(),
      //   "descripcion": this.Descripcion != undefined ? this.Descripcion : "",
      //   "cambioDatos": this.cliente.CambioDato == true ? "1" : "0"
      // }



      console.log(req);
      this.CargaCompleta = true;
      var respuesta = await this.MediwebServiceService.AgregarCita(req);
      this.CargaCompleta = false;
      console.log(respuesta);
      if (respuesta["status"] == false) {
        this.messageService.clear();
        this.messageService.add({ key: 'tc', severity: 'warn', summary: 'No se pudo Agendar', detail: respuesta["message"] });
      }
      else {
        var Cita = {
          "Sucursal": this.sucursal["sNombre"].toString(),
          "Direccion": this.sucursal["sDirec"].toString(),
          "Doctor": this.Doctor["nomDoc"].toString(),
          "Especialidad": this.especialidad["sNomEsp"].toString(),
          "fecha": this.FechaSelect.toString(),
          "hora": this.HoraSelect.toString(),
          "descripcion": this.Descripcion != undefined ? this.Descripcion : "",
          "idMas": this.cliente["IdMasc"],
          "Nombremasc": this.cliente["NombreMasc"],
          "codigoCita": respuesta["codAge"] + ""
        }
        localStorage.setItem('DatosCita', JSON.stringify(Cita));
        this.Router.navigate(["Resumen"]);
      }
    }


  }
  imprimir(alo) {
    console.log(alo);

  }

  formateaRut(rut) {
    var actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
      var sinPuntos = actual.replace(/\./g, "");
      var actualLimpio = sinPuntos.replace(/-/g, "");
      var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      var rutPuntos = "";
      var i = 0;
      var j = 1;
      for (i = inicio.length - 1; i >= 0; i--) {
        var letra = inicio.charAt(i);
        rutPuntos = letra + rutPuntos;
        if (j % 3 == 0 && j <= inicio.length - 1) {
          rutPuntos = "." + rutPuntos;
        }
        j++;
      }
      var dv = actualLimpio.substring(actualLimpio.length - 1);
      rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
  }


}
