<app-timeout></app-timeout>
<div class="container-agenda">
    <div class="row  container">
        <div class="col-12 text-center">
            <img src="./assets/images/logo_veterinaria.png"  height="150" alt="" loading="lazy" class="logo">
        </div>
        <div class="col-12 text-center">
            <h2 class="slogan">Salud de calidad a tu alcance </h2>
        </div>
        <div class="btnleft" (click)="clickderecho()">

        </div>
        <div class="btnrigth" (click)="clickizquierdo()">

        </div>
    </div>
    <!-- <button class="btn-siguiente ml-auto" routerLink="/LoginD" >
        <i class="pi pi-arrow-right"></i>
    </button> -->
    <button class="btn-siguiente ml-auto" routerLink="/Cliente-Agenda" >
        <i class="pi pi-arrow-right"></i>
    </button>
</div>
